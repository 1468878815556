import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap'
import Seo from '../components/seo'
import Layout from '../components/layout'
import CouncilForm from '../components/common/council-form'

const CouncilPage = () => {
  const videoContainerStyle = {
    paddingBottom: '177.92%',
    // paddingBottom: "100%",
    position: 'relative',
    width: '100%',
    height: 0,
  }
  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }

  return (
    <Layout>
      <Seo title="Culinary Council'" />
      <header id="collectiveHero" className="bg-dark">
        <div className="header-wave-top">
          <Row>
            <Col xxl="6" className="order-xxl-1 video-column">
              <div style={videoContainerStyle} className="video-container">
                <iframe
                  src="https://player.vimeo.com/video/721190720?h=10978b9e8d&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1&controls=0"
                  allow="autoplay;"
                  style={iframeStyle}
                  title="Meati™ Classic Cutlet"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
            </Col>

            <Col xxl="6" className="d-flex order-xxl-0">
              <Container className="text-white">
                <div className="px-lg-4 pt-2 pb-4">
                  <h1 className="fst-italic fw-bold">Join our Culinary Council</h1>
                  <p className="fs-5 mb-2">
                    We're building something from the ground up and need your help! By joining this community you'll receive early access to new product drops and the chance to try our products and provide input that will shape our portfolio of products.
                  </p>
                  <CouncilForm />
                  <h2 className="mt-3">How it works:</h2>
                  <ol className="fs-5 pe-xl-4">
                    <li>
                      When we have a new market-ready product we'll email you to opt into first access.
                    </li>
                    <li>
                      Let us know you want in!
                    </li>
                    <li>
                      We'll ship you the new product directly with instructions on how-to-prepare like a pro.
                    </li>
                    <li>
                      Try the product within 4 days of receiving and immediately submit the survey at the link provided.
                    </li>
                    <li>
                      Wait on baited breath for the next exclusive, Culinary Council product to drop!
                    </li>
                  </ol>
                </div>
              </Container>
            </Col>
          </Row>
          <div className="header-overlay" />
        </div>
      </header>
    </Layout>
  )
}

export default CouncilPage
